import React, { useEffect, useState } from "react";
import axios from "axios";

function AllProject() {
  const [projectData, setData] = useState([]);
  useEffect(() => {
    axios
      .get(
        "https://www.quadbackendapi.quadtheoryltd.com:444/api/Project/getProjects"
      )
      .then((response) => {
        setData(response.data);
      });
  }, []);
  return (
    <div>
      <div>
        <div className="sectionMergin  d-flex justify-content-center ">
          <div className=" themeColour pt-5 pb-5">
            <div className="row p-4 ">
              <div className="col-md-12 mb-3 ">
                <div className=" d-flex justify-content-center  text-uppercase ">
                  <h1 className=" text-center">
                    Some Of Our Developed Products For Partners
                  </h1>
                </div>
              </div>
              <div className="col-md-12 mb-3">
                <div className="text-uppercase  d-flex justify-content-center  ">
                  <p className=" fs-5 text-md-center text-start text-justify  ">
                    Our company specializes in software outsourcing and provides
                    robust, scalable, and efficient solutions to clients around
                    the world. We are committed to providing quality services at
                    competitive prices. Our goal is to help our clients achieve
                    their business objectives by providing them with the best
                    possible software solutions.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sectionMergin  d-flex justify-content-center ">
          <div className=" container-fluid sectionContainer  mt-5 ">
            <div className="row">
              {projectData.map((data) => {
                return (
                  <div className="col-md-6 col-12" key={data.id}>
                    <div className="card projectCard">
                      <img
                        src={data.image}
                        className="card-img-top"
                        alt="Service Image"
                      />
                      <div className="card-body">
                        <h3 className="card-title">{data.projectName}</h3>
                        <p className="card-text">{data.projectDescription}</p>
                        <a href={"/project/" + data.id} className=" readMore">
                          Explore More
                        </a>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AllProject;
