import axios from "axios";
import React, { useState } from "react";
import loaderGif from "./../../../Assets/Images/Loader.gif";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [subject, setsubject] = useState("");
  const [message, setmessage] = useState("");
  const [submiting, setsubmiting] = useState(false);

  let handleSubmit = async (e) => {
    e.preventDefault();
    console.log(e);
    setsubmiting(true);
    var obj = {
      id: "string",
      name: name,
      email: email,
      mobileNumber: mobile,
      subject: subject,
      message: message,
      creationdate: new Date(),
    };

    axios
      .post(
        "https://www.quadbackendapi.quadtheoryltd.com:444/api/BusinessQuery",
        obj
      )
      .then((response) => {
        console.log(response);
        setsubmiting(false);
        setName("");
        setEmail("");
        setMobile("");
        setmessage("");
        setsubject("");
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div className="custom-ui">
                <h2>Thank you for comunicating with us</h2>
                <p>One of our representative will connect with you soon</p>
                <button
                  className="btn-yes btn-common"
                  onClick={() => {
                    onClose();
                  }}
                >
                  Ok
                </button>
              </div>
            );
          },
        });
      });
  };

  const [isValid, setIsValid] = useState(true);

  const validateMobileNumber = (input) => {
    // Assuming a simple pattern for a valid mobile number (e.g., 10 digits)
    const mobileNumberPattern = /^\d{10}$/;

    return mobileNumberPattern.test(input);
  };

  const handleMobileChange = (e) => {
    const inputValue = e.target.value;
    console.log(inputValue);
    console.log(validateMobileNumber(inputValue));
    if (/^\d*$/.test(inputValue) && inputValue.length <= 11) {
      setMobile(inputValue);
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  return (
    <div>
      <div className="">
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <input
              type="text"
              className="form-control"
              id="name"
              placeholder="Name"
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <input
              type="email"
              className="form-control"
              id="email"
              placeholder="Email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <input
              type="text"
              className="form-control"
              id="mobileNumber"
              placeholder="Mobile Number"
              required
              value={mobile}
              onChange={handleMobileChange}
            />
            <span
              className={`${isValid ? "d-none" : ""}`}
              style={{ color: "#E74C3C" }}
            >
              Not A Valid Number
            </span>
          </div>
          <div className="mb-3">
            <input
              type="text"
              className="form-control"
              id="subject"
              placeholder="Subject"
              required
              value={subject}
              onChange={(e) => setsubject(e.target.value)}
            />
          </div>
          <div className="form-outline">
            <textarea
              className="form-control"
              id="message"
              placeholder="Message"
              rows="4"
              required
              value={message}
              onChange={(e) => setmessage(e.target.value)}
            ></textarea>
          </div>
          <div className="mt-4 d-flex justify-content-end">
            {submiting ? (
              <button
                className="button-submit"
                style={{ pointerEvents: "none" }}
              >
                <img src={loaderGif} alt="" style={{ width: "1.5rem" }} />
              </button>
            ) : (
              <input type="submit" value="Send" className="button-submit" />
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default Contact;
