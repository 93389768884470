import React from "react";
import "./NavBar.css";
import logo from "./../../../Assets/Images/Logo-removebg-preview.png";
import { Link } from "react-scroll";
import { useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

function NavBar(props) {
  const navigate = useNavigate();
  return (
    <div className="sticky">
      <div className="container">
        <nav className="navbar navbar-expand-lg   ps-md-5 pe-md-5 navbar-custom ">
          <div className="container-fluid ms-2">
            <a className="navbar-brand" href="/">
              <img src={logo} alt="" style={{ width: "5rem" }} />
              Quad Theory
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav me-auto mb-2 mb-lg-0 customNav">
                <li className="nav-item">
                  <a className="nav-link " aria-current="page" href="/">
                    Home
                  </a>
                </li>
                <div className="dropdown">
                  <button
                    className="btn dropdown-toggle dropdown-button nav-link"
                    type="button"
                    id="dropdownMenuButton"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    style={{
                      backgroundColor: "transparent",
                      color: "black",
                      borderColor: "transparent",
                    }}
                  >
                    Our Services
                  </button>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <a
                      className="dropdown-item"
                      href="/offshore-developer-services-quad-theory-ltd"
                    >
                      Offshore Developer Service
                    </a>
                    <a
                      className="dropdown-item"
                      href="/web-development-software-company"
                    >
                      Web Development
                    </a>
                    <a
                      className="dropdown-item"
                      href="/business-analysis-software-company"
                    >
                      Business Analysis
                    </a>
                    <a
                      className="dropdown-item"
                      href="/communications-technology-solutions-software-company"
                    >
                      Communications technology
                    </a>
                    <a
                      className="dropdown-item"
                      href="/data-analytics-software-company"
                    >
                      Data Analytics
                    </a>
                    <a className="dropdown-item" href="/our-services">
                      <b>See More</b>
                    </a>
                  </div>
                </div>

                {/* <li className="nav-item">
                                    <a className="nav-link " aria-current="page"
                                        onClick={() => {
                                            navigate("/Article")
                                        }}>Articles</a>
                                </li> */}

                <li className="nav-item">
                  {/* <a
                    className="nav-link "
                    aria-current="page"
                    onClick={() => {
                      navigate("/our-services");
                    }}
                  >
                    Clients
                  </a> */}
                  <HashLink to="/#client" className="nav-link ">
                    Clients
                  </HashLink>
                </li>

                {/* <li className="nav-item">
                  <Link
                    className="home"
                    to="technology"
                    spy={true}
                    smooth={true}
                    duration={500}
                  >
                    Technology
                  </Link>
                </li> */}

                <li className="nav-item">
                  {/* <a
                    className="nav-link "
                    aria-current="page"
                    // onClick={() => {
                    //   navigate("/#technology");
                    //   window.href
                    // }}
                    href="/#technologyweuse"
                  >
                    Technology
                  </a> */}
                  <HashLink to="/#technologyweuse" className="nav-link ">
                    Technology
                  </HashLink>
                </li>

                <li className="nav-item">
                  <HashLink to="/#faq" className="nav-link ">
                    FAQ
                  </HashLink>
                  {/* <a
                    className="nav-link "
                    aria-current="page"
                    // onClick={() => {
                    //   navigate("/our-services");
                    // }}
                    href="/#faq"
                  >
                    FAQ
                  </a> */}
                </li>

                <li className="nav-item">
                  <a
                    className="nav-link "
                    aria-current="page"
                    onClick={() => {
                      navigate("/Career");
                    }}
                  >
                    Career
                  </a>
                </li>

                <li className="nav-item">
                  <a
                    className="nav-link "
                    aria-current="page"
                    onClick={() => {
                      navigate("/our-services");
                    }}
                  >
                    Contact
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
}

export default NavBar;
