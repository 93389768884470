import React, { useEffect, useState } from "react";
import axios from "axios";
import Aos from "aos";
import "aos/dist/aos.css";

function OpenPosition() {
  const [postionData, setData] = useState([]);
  const [isIntern, setisIntern] = useState(false);
  useEffect(() => {
    axios
      .get(
        "https://www.quadbackendapi.quadtheoryltd.com:444/api/Position/api/Position/showAllQuestion"
      )
      .then((response) => {
        setData(response.data);
      });
    Aos.init({
      easing: "ease-in-sine",
    });
  }, []);
  return (
    <div>
      <div
        className="modal fade modal-lg"
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {isIntern ? (
                  <> React Internship</>
                ) : (
                  <> .NET Software Developer</>
                )}
              </h5>
              <button
                type="button "
                className="close btn"
                // className="btn-new "
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {isIntern ? (
                <div class="container">
                  <h1>Paid Internship at Quad Theory Ltd</h1>
                  <p>
                    Grab this opportunity to be a part of an enthusiastic young
                    team!!!
                  </p>
                  <h1>Internship Details</h1>
                  <p>
                    <span class="">
                      <b>Duration:</b>
                    </span>{" "}
                    04 months
                  </p>
                  <p>
                    <span class="">
                      <b>Location:</b>
                    </span>{" "}
                    Anywhere In Bangladesh (Remote)
                  </p>

                  <h2>Requirements:</h2>
                  <ul>
                    <li>Strong interest in front-end development.</li>
                    <li>
                      Basic knowledge of HTML, CSS, JavaScript, and ReactJS.
                    </li>
                    <li>Familiarity with Redux, and Material UI is a plus.</li>
                    <li>
                      Enthusiasm for learning and adapting to new technologies.
                    </li>
                    <li>
                      Ability to work collaboratively in a team environment.
                    </li>
                  </ul>

                  <h2>Key Responsibilities:</h2>
                  <ul>
                    <li>
                      Assist in the development of responsive and user-friendly
                      web applications.
                    </li>
                    <li>
                      Collaborate with cross-functional teams to implement new
                      features.
                    </li>
                    <li>Debug and troubleshoot technical issues.</li>
                    <li>
                      Participate in code reviews and contribute to continuous
                      improvement.
                    </li>
                  </ul>

                  <h2>What We Offer:</h2>
                  <ul>
                    <li>
                      Hands-on experience in a real-world project environment.
                    </li>
                    <li>
                      Mentorship from experienced professionals in the field.
                    </li>
                    <li>
                      Exposure to cutting-edge technologies and industry best
                      practices.
                    </li>
                    <li>
                      Opportunities for skill enhancement and career
                      development.
                    </li>
                  </ul>

                  <h2>Application Process:</h2>
                  <p>Please send your CV with the following subject line:</p>
                  <p class="text-primary">QT_React_I_FULL_NAME</p>
                  <p>Example: QT_React_I_Masud_Rana</p>
                  <p>Please mention your mobile number in the email body.</p>
                  <p>
                    Email:{" "}
                    <a href="mailto:  hr@quadtheoryltd.com">
                      hr@quadtheoryltd.com
                    </a>
                  </p>
                  <p>
                    Company Website:{" "}
                    <a href="https://quadtheoryltd.com/">Quad Theory Ltd.</a>
                  </p>
                </div>
              ) : (
                <div class="container">
                  <h1 class="mt-1">Job Position: .NET Software Developer</h1>
                  <p>
                    <strong>Company:</strong> Quad Theory Ltd.
                  </p>
                  <p>
                    <strong>No. of Vacancies:</strong> 5
                  </p>
                  <p>
                    <strong>Year of Experience:</strong> 2-5 years
                  </p>
                  <p>
                    <strong>Job Type:</strong> Remote job
                  </p>
                  <p>
                    <strong>Monthly Salary:</strong> Salary is not a constraint
                    for the right candidate.
                  </p>

                  <h2 class="mt-4">Job Responsibilities:</h2>
                  <ul>
                    <li>
                      Write quality code for large-scale web-based applications
                      using ASP.NET Core as well as SQL and Front-End Frameworks
                      like Blazor.
                    </li>
                    <li>Problem-solver and team player.</li>
                    <li>Proactive, quick learner, and energetic.</li>
                    <li>
                      Responsible for full life cycle application development
                      and deployment.
                    </li>
                    <li>Maintain architectural integrity.</li>
                    <li>
                      Identify the common components, and architectural
                      solutions, as well as internal and external APIs.
                    </li>
                    <li>
                      Review the technical code according to the company
                      standard.
                    </li>
                  </ul>

                  <h2 class="mt-4">Educational Requirements:</h2>
                  <p>BSC/MSC in Computer Science & Engineering.</p>

                  <h2 class="mt-4">Experience Requirements:</h2>
                  <ul>
                    <li>
                      Experience with C#, ASP.NET, ASP.NET MVC, Web API, SQL
                      Server 2014+ (Stored Procedures / Schema), ASP .NET Core
                      and Blazor
                    </li>
                    <li>Experience working in SCRUM or Agile Methodologies.</li>
                  </ul>

                  <h2 class="mt-4">Knowledge & Skills Requirements:</h2>
                  <ul>
                    <li>
                      Knowledge in SQL Server, SQL Server Reporting Services
                      (SSRS), or SQL Server Integration Services (SSIS)
                    </li>
                    <li>
                      Microsoft stack of technologies with C# as the main
                      programming language and .NET 6 as the main framework
                    </li>
                    <li>Must have knowledge of OOP concepts</li>
                    <li>
                      Architecture Design: Repository Pattern, Onion
                      Architecture, Clean Architecture & Dependency Injection
                      Design Pattern.
                    </li>
                    <li>
                      A strong advocate for unit testing, code reviews, and
                      other best practices
                    </li>
                    <li>English level basic-intermediate.</li>
                    <li>Solid knowledge using LINQ and Entity Framework.</li>
                    <li>Front-end experience (Blazor)</li>
                  </ul>

                  <p>
                    <strong>Employment Type:</strong> Full-time
                  </p>
                  <p>
                    Please send your CV with the following subject line:
                    QT_DOTNET_M_FULL_NAME_YEARS_OF_EXPERIENCE
                  </p>
                  <p>Example: QT_DOTNET_Leo_02</p>
                  <p>
                    Email:{" "}
                    <a href="mailto:  hr@quadtheoryltd.com">
                      hr@quadtheoryltd.com
                    </a>
                  </p>
                  <p>
                    Company Website:{" "}
                    <a href="https://quadtheoryltd.com/">Quad Theory Ltd.</a>
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="  sectionMergin pt-3 pb-3">
          <div className="container-fluid d-flex justify-content-center">
            <div className=" sectionContainer">
              <div
                className="row"
                data-aos="fade-up"
                data-aos-anchor-placement="bottom-bottom"
              >
                <div
                  className="col-lg-12 text-center pb-3"
                  style={{
                    color: "black",
                    fontWeight: "500",
                    fontFamily: "Montserrat",
                    fontSize: "36px",
                  }}
                >
                  <span>Open Position</span>
                </div>
              </div>
              <div
                className="row d-flex justify-content-center"
                data-aos="fade-up"
                data-aos-anchor-placement="bottom-bottom"
              >
                {/* {postionData.map((data) => {
                  return (
                    <div className="col-md-4 col-12 mb-4 " key={data.id}>
                      <div
                        className=" container rounded-4 p-md-3 p-3"
                        style={{ backgroundColor: "rgb(142 223 223)" }}
                      >
                        <div>
                          <span className=" fs-5 fw-semibold text-uppercase">
                            {data.positionName}
                          </span>
                          <p className=" fs-6 fst-italic fw-lighter">
                            {data.location}
                          </p>
                        </div>
                        <div className="d-flex justify-content-end me-3">
                          <a href="#" className=" text-decoration-none ">
                            Apply Now
                          </a>
                        </div>
                      </div>
                    </div>
                  );
                })} */}
                <div className="col-lg-4 col-12 mb-4 ">
                  <div
                    className=" container rounded-4 p-md-3 p-3"
                    style={{
                      boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                    }}
                  >
                    <div>
                      <span className=" fs-5 fw-semibold text-uppercase">
                        React Internship
                      </span>
                      <p className=" fs-6 fst-italic fw-lighter">
                        Work from home
                      </p>
                    </div>
                    <div className="d-flex justify-content-end me-3">
                      <button
                        type="button"
                        class="btn-new "
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        onClick={() => {
                          setisIntern(true);
                        }}
                      >
                        Apply Now
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-12 mb-4 ">
                  <div
                    className=" container rounded-4 p-md-3 p-3"
                    style={{
                      boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                    }}
                  >
                    <div>
                      <span className=" fs-5 fw-semibold text-uppercase">
                        Dot NET Software Developer
                      </span>
                      <p className=" fs-6 fst-italic fw-lighter">
                        Work from home
                      </p>
                    </div>
                    <div className="d-flex justify-content-end me-3">
                      <button
                        type="button"
                        class=" btn-new "
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        onClick={() => {
                          setisIntern(false);
                        }}
                      >
                        Apply Now
                      </button>
                    </div>
                  </div>
                </div>
                {/* <h4 className="text-center">Currently we are not hiring</h4> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OpenPosition;
