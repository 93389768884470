import React, { useEffect } from "react";
import BannerSection from "./BannerSection/BannerSection";
import WhoWeAre from "./WhoWeAre/WhoWeAre";
import Service from "./Services/Service";
import Product from "./Products/Product";
import Technology from "./Technology/Technology";
import Review from "./Review/Review";
import Article from "./Article/Article";
import AskedQuestion from "./AskedQuestion/AskedQuestion";
import Location from "./Location/Location";
import Contact from "./Location/Contact";
import ChooseQuad from "./ChooseQuad/ChooseQuad";
import "./Home.css";
import Footer from "./Footer/Footer";
import NavBar from "./NavBar/NavBar";
import TrustedBySection from "../TrustedBy/TrustedBy";
import IndustriesSection from "../IndustriesWorkWith/Industries";

const Home = () => {
  useEffect(() => {
    //window.scrollTo(0, 0);
  }, []);
  return (
    <div className="main-section-color">
      <BannerSection fromText={" Your Digital Universe, Our Expertise"} />
      <TrustedBySection />
      <WhoWeAre />
      <Service />
      <Product />
      <Technology />
      <IndustriesSection />
      <Review />
      {/* <Article /> */}
      <AskedQuestion />
    </div>
  );
};

export default Home;
