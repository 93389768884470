import React, { useEffect, useState } from "react";
import axios from "axios";
import Aos from "aos";
import "aos/dist/aos.css";

const AskedQuestion = () => {
  const [questionData, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAll();
    Aos.init({
      easing: "ease-in-sine",
    });
  }, []);

  const getAll = async () => {
    setLoading(true);
    await axios
      .get(
        "https://www.quadbackendapi.quadtheoryltd.com:444/api/Question/showAllQuestion"
      )
      .then((response) => {
        setData(response.data);
        setLoading(false);
      });
  };
  if (loading) {
    return (
      <div className="d-flex justify-content-center">
        <img src={require("./../../../Assets/Images/Loader.gif")} />
      </div>
    );
  } else {
    return (
      <div className="container-fluid sectionMergin">
        <div className="">
          <div className="">
            <div
              className="row"
              data-aos="fade-up"
              data-aos-anchor-placement="bottom-bottom"
            >
              <div className="col-md-12 ">
                <h2
                  className=" text-center  fs-1 mb-3 mt-3 "
                  style={{
                    color: "black",
                    fontWeight: "500",
                    fontFamily: "Montserrat",
                    fontSize: "36px",
                  }}
                >
                  Frequently Asked Question
                </h2>
              </div>
            </div>
            <div
              className="row"
              data-aos="fade-up"
              data-aos-anchor-placement="bottom-bottom"
            >
              <div className="d-flex justify-content-center">
                <div className="col-md-10">
                  <div className="d-flex justify-content-center">
                    <h6 className=" fw-semibold text-center mb-5 ">
                      We are more than a generic agency. Explore our Services
                      and our Solutions for your business.
                    </h6>
                  </div>
                </div>
              </div>
            </div>

            <div className="row " style={{ paddingBottom: "50px" }}>
              <div
                className="accordion d-grid gap-4 sectionContainer"
                style={{ margin: "Auto" }}
              >
                {questionData.map((data) => {
                  return (
                    <div
                      className="accordion-item rounded-4"
                      data-aos="fade-up"
                      data-aos-anchor-placement="bottom-bottom"
                      key={data.id}
                    >
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button gradientColor collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={"#" + data.id}
                          aria-expanded="true"
                          aria-controls={data.id}
                          style={{ color: "white" }}
                        >
                          {data.name}
                        </button>
                      </h2>
                      <div
                        id={data.id}
                        className="accordion-collapse collapse "
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                        style={{ backgroundColor: "#117488", color: "white" }}
                      >
                        <div className="accordion-body">{data.answer}</div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default AskedQuestion;
