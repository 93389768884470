import React, { useEffect } from "react";
import Contact from "./Contact";
import Aos from "aos";
import "aos/dist/aos.css";

const Location = () => {
  useEffect(() => {
    Aos.init({
      easing: "ease-in-sine",
    });
  }, []);
  return (
    <div className="container-fluid d-flex justify-content-center sectionMergin">
      <div className="sectionContainer">
        <div
          className="row"
          data-aos="fade-up"
          data-aos-anchor-placement="bottom-bottom"
        >
          <div className="col-md-12 ">
            <h2 className=" text-center fs-1 mb-5 "  style={{color: "black", fontWeight: "500", fontFamily: "Montserrat", fontSize: "36px"}}>
              Grow Your Business With Our Expertise
            </h2>
          </div>
        </div>

        <div className="row">
          <div
            className="col-lg-6"
            data-aos="fade-up"
            data-aos-anchor-placement="bottom-bottom"
          >
            <Contact />
          </div>
          <div
            className="col-lg-6"
            data-aos="fade-up"
            data-aos-anchor-placement="bottom-bottom"
          >
              
              <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1773.784902641383!2d90.42732915506487!3d23.76443933129503!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755c71aaeb538a7%3A0x94846b3432055426!2sQuad%20Theory%20Ltd.!5e0!3m2!1sbn!2sbd!4v1687866475424!5m2!1sbn!2sbd"

             
              // src="https://www.google.com/maps/embed/v1/place?q=5+Brayford+Square,+London+E1+0SG,+UK&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
             
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              className="location"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Location;
