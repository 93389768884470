import React, { useEffect } from "react";

import headerImage from "./../../../Assets/Images/about_img.webp";
import "./WhoWeAre.css";
import Aos from "aos";
import "aos/dist/aos.css";
const WhoWeAre = () => {
  useEffect(() => {
    Aos.init({
      easing: "ease-in-sine",
    });
  }, []);
  return (
    <>
      <h2
        className="d-flex justify-content-center content-center mt-4"
        data-aos="fade-up"  style={{color: "black", fontWeight: "500", fontFamily: "Montserrat", fontSize: "36px"}}
      >
        Who We are?
      </h2>
      <div className="d-flex justify-content-center container-fluid custom-backgroud sectionMergin ps-4 pe-4 pt-4 ">
        <div className="d-flex justify-content-center ps-md-5 pe-md-5 ">
          {/* <div className="card-custom"> */}
          <div className="row align-items-center" data-aos="fade-up">
          <div className="d-flex justify-content-center col-lg-6 order-2 order-md-1">
              <div>
                <p
                  className="d-flex justify-content-center content-center text-align-justify text-font mt-4"
                  // style={{ fontSize: "1.2rem", textAlign: "justify" }}
                >
                  At Quad Theory Ltd, we're more than just an IT company –
                  we're your partners in navigating the ever-evolving landscape
                  of technology. We are driven by a passion for innovation and a
                  commitment to delivering cutting-edge IT solutions that
                  empower businesses and individuals to thrive in a connected
                  world.
                </p>
              </div>
            </div>

            <div className="col-lg-6 d-flex justify-content-center p-0 order-1 order-md-2 desktopView">
              <div
                className=" image-container"
                style={{ position: "relative", width: "80%" }}>
                <div className="">
                  <img
                    src={require("./../../../Assets/Images/Logo-removebg-preview.png")}
                    className="image"
                    
                  />
                  <h5>Quad Theory</h5>
                </div>
                <div className="firstimage">
                  <img
                    src={require("./../../../Assets/Images/React.png")}
                    alt=".."
                    className="image-anim"
                  />
                </div>
                <div className="secondimage">
                  <img
                    src={require("./../../../Assets/Images/DotNet.png")}
                    alt=".."
                    className="image-anim"
                  />
                </div>
                <div className="thirdimage">
                  <img
                    src={require("./../../../Assets/Images/Azure.png")}
                    alt=".."
                    className="image-anim"
                  />
                </div>
                <div className="fourthimage">
                  <img
                    src={require("./../../../Assets/Images/ms-sql-server-filled.png")}
                    alt=".."
                    className="image-anim"
                  />
                </div>
                <div className="fivethimage">
                  <img
                    src={require("./../../../Assets/Images/Android.png")}
                    alt=".."
                    className="image-anim"
                  />
                </div>
              </div>

              <div className="img-div">
                {/* <img src={headerImage} alt=".." className="img-fluid" /> */}
                {/* <img
                  src={require("./../../../Assets/Images/about-us-removebg-preview.png")}
                  alt=".."
                  className="img-fluid h-100"
                /> */}
                {/* <div className="imgtext">
                  <h2
                  className="d-flex justify-content-center content-center  fw-bold fs-1 mb-5"
                  data-aos="fade-up"
                >
                  Who We are?
                </h2>
                </div> */}
              </div>
            </div>
          </div>
          {/* </div> */}
        </div>
      </div>
    </>
  );
};

export default WhoWeAre;