import React, { useEffect } from "react";
import Go5050 from "./../../../Assets/Images/go5050logo2 1.png";
import "./Review.css";
import Aos from "aos";
import "aos/dist/aos.css";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

const Review = () => {
  useEffect(() => {
    Aos.init({
      easing: "ease-in-sine",
    });
  }, []);
  return (
    <div className="" style={{ height: "100%" }}>
      <div
        className="d-flex justify-content-center align-items-center sectionMergin  "
        style={{}}
      >
        <div className="sectionContainer">
          {/* <div className="row" data-aos="fade-up"> */}
          <div className="row">
            <div className="col-lg-12 d-flex justify-content-center p-4 ">
              <h1
                className="text-center mb-1"
                style={{
                  color: "black",
                  fontWeight: "500",
                  fontFamily: "Montserrat",
                  fontSize: "36px",
                  marginTop: "10px",
                }}
              >
                Stories From Our Amazing Clients
              </h1>
            </div>
          </div>

          <div className="row">
            <div className="image-position">
              {/* <img
                className="img-fluid"
                // style={{ height: "100%", position: "relative" }}
                src={require("./../../../Assets/Images/double-quotes.png")}
              /> */}
            </div>

            <div className="swiper-div">
              <Swiper
                spaceBetween={30}
                centeredSlides={true}
                autoplay={{
                  delay: 5000,
                  disableOnInteraction: false,
                }}
                pagination={{
                  clickable: true,
                }}
                navigation={false}
                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper"
              >
                {/* <SwiperSlide>
                  <div
                    className=" d-flex justify-content-center cl-lg-12 card-custom-review"
                    data-aos="fade-up"
                  >
                    <div className="row">
                      <div className="d-flex justify-content-center ps-md-5 pe-md-5">
                        <div className="card-content">
                          <div className="client-img-center">
                            <img
                              src={Go5050}
                              className=" img- img-review client-img-center"
                              alt="Service Image"
                            />
                          </div>
                          <div className="text-start me-md-5 me-2 fw-light revierwer-name-color">
                            <h5>Leo Kameni, Founder of Go5050</h5>
                          </div>
                          <hr className="custom-border-bottom"></hr>
                          <p className="fw-light reveiw-thought">
                            “Heartfelt appreciation to Quad Theory Ltd Limited
                            for believing in my vision. Their talented
                            developers can take any challenges against all odds
                            and helped to bring GO5050 into life.”
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide> */}
                <SwiperSlide>
                  <div
                    className=" d-flex justify-content-center cl-lg-12 card-custom-review"
                    data-aos="fade-up"
                  >
                    <div className="row">
                      {/* <div className="col-md-4">
                        <div className="me-5 me-2">
                          <p className="pt-4 fs-5 fw-light revierwer-name-color">
                           Mobile App Development
                          </p>
                          <h5 className="me-6 pb-5">Project</h5>
                        </div>
                      </div> */}
                      {/* <div className="col-md-8"> */}
                      <div className="d-flex justify-content-center ps-md-5 pe-md-5">
                        <div className="card-content">
                          <div className="client-img-center">
                            <img
                              src={require("./../../../Assets/Images/logo (1).png")}
                              className=" img-review"
                              // style={{height:"7%", width:"18%"}}
                              alt="Service Image"
                            />
                          </div>
                          <div className="text-start me-md-5 mt-2 revierwer-name-color">
                            <h5>
                              S K Mukhopadhyay, CEO Of Infinigent Consulting
                            </h5>
                            {/* <p className="fs-5 fw-light">
                          Gazi,Paragon Hotel and Resort
                          </p> */}
                          </div>
                          <hr className="custom-border-bottom"></hr>
                          {/* <p className="text-start custom-border-bottom">-</p> */}
                          {/* <p className="fw-light fst-italic reveiw-thought"> */}
                          <p className="fw-light reveiw-thought">
                            “Enormous gratitude to the team at Quad Theory Ltd
                            Limited for their unwavering support and dedication
                            to my project. Their highly skilled developers not
                            only accepted every challenge that came their way
                            but also conquered them against all odds, playing a
                            pivotal role in bringing my vision to life.”
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div
                    className=" d-flex justify-content-center cl-md-12 card-custom-review"
                    data-aos="fade-up"
                  >
                    <div className="row">
                      {/* <div className="col-md-4">
                        <div className="text-end me-md-5 me-2">
                          <p className="pt-4 fs-5 fw-light revierwer-name-color">
                            Hotel Management 
                          </p>
                          <h5 className="me-6 pb-5">Project</h5>
                        </div>
                      </div>
                      <div className="col-md-8"> */}
                      <div className="d-flex justify-content-center ps-md-5 pe-md-5">
                        <div className="card-content ">
                          <div className="client-img-center">
                            <img
                              src={require("./../../../Assets/Images/image001.png")}
                              className=" img-review client-img-center"
                              // style={{height:"15%", width:"25%"}}
                              alt="Service Image"
                            />
                          </div>

                          <div className="text-start me-md-5 me-2 fw-light revierwer-name-color">
                            <h5>Gazi, Paragon Hotel and Resort</h5>
                            {/* <p className="fs-5 fw-light">
                          Gazi,Paragon Hotel and Resort
                          </p> */}
                          </div>
                          <hr className="custom-border-bottom"></hr>
                          {/* <p className="text-start custom-border-bottom">-</p> */}
                          {/* <p className="fw-light fst-italic reveiw-thought"> */}
                          <p className="fw-light reveiw-thought">
                            "Quad Theory Ltd has truly outdone themselves with
                            their Hotel Management Project. This innovative
                            system has streamlined hotel operations and improved
                            the overall guest experience. The user-friendly
                            interface and robust management tools have made our
                            job much easier, and the continuous improvements
                            from Quad Theory Ltd demonstrate their commitment to
                            excellence. Great job to the Quad Theory Ltd team
                            for creating a fantastic solution that is making a
                            big difference in the hotel business."
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
              <div id="faq"></div>
            </div>
          </div>

          {/* <div className="row bg-white client-review" data-aos="fade-up">
            <div className="col-md-6 col-12">
              <div className="d-flex justify-content-center d-flex align-items-center ">
                <div className=""></div>
                <img src={Go5050} alt="Go5050" className="img-fluid" />
              </div>
            </div>
            <div className="col-md-6 col-12 d-flex justify-content-center d-flex align-items-center">
              <div>
                <p className="     fw-light fst-italic">
                  “Heartfelt appreciation to Quad Theory Ltd Limited for believing
                  in my vision. Their talented developers can take any
                  challenges against all odds and helped to bring GO5050 into
                  life.”
                </p>

                <h5 className=" text-end me-md-5 me-2    ">
                  Leo Kameni
                  <p className=" fs-5 fw-light ">Founder of Go5050</p>
                </h5>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <div id="faq" style={{ paddingBottom: "5rem" }}></div>
    </div>
  );
};

export default Review;
