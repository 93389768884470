import React, { useEffect } from "react";
import bannerImage from "./../../../Assets/Images/CareerBanner.png";
import careerImg from "./../../../Assets/Images/career2.jpg"
import './CareerSection.css'
import Aos from "aos";
import 'aos/dist/aos.css'

function CareerBanner() {
  useEffect(() => {
    Aos.init({
      easing: 'ease-in-sine'
    })
  }, [])
  return (
    <div>
      <div>
        <div className="bannerImgForCareer">

        </div>
        <div className="container-fluid sectionMergin" style={{height:"100vh"}}>
          <div className="row d-flex text-lg-start text-center align-items-center h-100">
            <div className="col-lg-6" style={{height:"100vh",display:"flex",justifyContent:"center",alignItems:"center"}} data-aos="fade-up">
              <div className="ms-lg-5">
                <div className=" careerText mt-4">
                  <h1>Career</h1>
                </div>

                <div className=" mt-5 ">
                  <p className=" careerTextP ">
                    At Quad Theory Ltd, we believe in empowering our team to reach their full potential. We offer a rich array of benefits and opportunities for professional and personal growth.
                  </p>
                </div>
                <div className="">
                  <a href="#openPosition" className=" btn btn-new mt-5">
                    Apply Now
                  </a>
                </div>
              </div>
            </div>
            {/* <div className="col-md-5 d-flex justify-content-center" data-aos="fade-up">
              <div className="bannerImageForCareer">
                <img src={careerImg} alt=".." className="img-fluid" />
              </div>
            </div> */}
          </div>

        </div>
      </div>
    </div>
  );
}

export default CareerBanner;
