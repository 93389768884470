import React, { useEffect, useState } from "react";
import axios from "axios";

function Feature({ id }) {
  const [featureData, setData] = useState([]);
  useEffect(() => {
    axios
      .get(
        `https://www.quadbackendapi.quadtheoryltd.com:444/api/Feature/getFeatures?id=${id}`
      )
      .then((response) => {
        setData(response.data);
      });
  }, []);
  return (
    <div>
      <div>
        <div className="container-fluid d-flex justify-content-center sectionMergin">
          <div className=" sectionContainer themeColour rounded-3">
            <div className="row p-4">
              <div className="col-md-12 ">
                <p className=" fs-2 fw-semibold">Features</p>
              </div>
              <div className="col-md-12 pb-5 ">
                <div className="row ps-4 pb-5">
                  {featureData.map((data) => {
                    return (
                      <div className="col-sm-6 fs-4 fw-lighter" key={data.id}>
                        <li>{data.featureName}</li>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Feature;
