import React, { useEffect, useState } from "react";
import "./AllServices.css";
import NavBar from "../HomePage/NavBar/NavBar";
import BannerSection from "../HomePage/BannerSection/BannerSection";
import AllServiceList from "./AllServiceList/AllServiceList";
import axios from "axios";
import Footer from "../HomePage/Footer/Footer";
function AllServices(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <BannerSection
        from={"service"}
        fromText={" Discover the Quad Theory Ltd IT Experience"}
      />
      <AllServiceList />
    </div>
  );
}

export default AllServices;
