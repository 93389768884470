import React, { useEffect } from "react";
import flex from "./../../../Assets/Icons/flexibility.png";
import './Benifit.css'
import Aos from "aos";
import 'aos/dist/aos.css'
function Benefit() {
  useEffect(() => {
    Aos.init({
      easing: 'ease-in-sine'
    })
  }, [])
  return (
    <div>
      <div className="   sectionMergin pt-md-3 pb-3 mt-md-5 benifit-section">
        <div className=" d-flex justify-content-center align-items-center w-100 " >
          <div className="w-100 ">
            <div className="row" data-aos="fade-up">
              <div className="col-md-12 text-center mobile-margin-top-md-screen"  style={{color: "black", fontWeight: "500", fontFamily: "Montserrat", fontSize: "40px"}}>
                <span>Benefits and Opportunities</span>
              </div>
              {/* <div className=" col-md-12 text-center fs-4 fw-light pb-5">
                <span>
                  At Quad Theory Ltd, we believe in empowering our team to reach
                  their full potential. We offer a rich array of benefits and
                  opportunities for professional and personal growth.
                </span>
              </div> */}
            </div>
            <div className="container-fluid">
              <div className="row mt-5 ms-mb-5">

              <div className="col-md-4 col-lg-3 mt-4 text-center text-md-start"
                  data-aos="fade-up">
                  <div className="benifitBox w-100 bg-white p-5">
                    <h2>Innovative Environment</h2>
                    <p>Immerse yourself in a dynamic workspace where creativity, innovation, and cutting-edge technology converge.</p>
                  </div>
                </div>
                <div className="col-md-4 col-lg-3 mt-4 text-center text-md-start"
                  data-aos="fade-up">
                  <div className="benifitBox w-100 bg-white p-5">
                    <h2>Professional Growth</h2>
                    <p>Access continuous learning opportunities, training programs, and mentorship to fuel your career development.</p>
                  </div>
                </div>
                <div className="col-md-4 col-lg-3 mt-4 text-center text-md-start"
                  data-aos="fade-up">
                  <div className="benifitBox w-100 bg-white p-5">
                    <h2>Collaborative Culture</h2>
                    <p>Thrive within a collaborative atmosphere where diverse talents come together to solve complex challenges.</p>
                  </div>
                </div>
                <div className="col-md-4 col-lg-3 mt-4 text-center text-md-start"
                  data-aos="fade-up">
                  <div className="benifitBox w-100 bg-white p-5">
                    <h2>Impactful Projects</h2>
                    <p>Contribute to projects that make a difference, from shaping industries to improving lives through transformative solutions.</p>
                  </div>
                </div>
                <div className="col-md-4 col-lg-3 mt-4 text-center text-md-start"
                  data-aos="fade-up">
                  <div className="benifitBox w-100 bg-white p-5">
                    <h2>Work-Life Balance</h2>
                    <p>Enjoy a supportive work environment that values your well-being, offering flexible schedules and remote work options.</p>
                  </div>
                </div>
                <div className="col-md-4 col-lg-3 mt-4 text-center text-md-start"
                  data-aos="fade-up">
                  <div className="benifitBox w-100 bg-white p-5">
                    <h2>Recognition and Rewards</h2>
                    <p>Your hard work doesn't go unnoticed – we believe in recognizing and rewarding excellence.</p>
                  </div>
                </div>
                <div className="col-md-4 col-lg-3 mt-4 text-center text-md-start"
                  data-aos="fade-up">
                  <div className="benifitBox w-100 bg-white p-5">
                    <h2>Skill Enhancement</h2>
                    <p>Elevate your skills by working on diverse projects that offer new challenges and opportunities for growth.</p>
                  </div>
                </div>
                <div className="col-md-4 col-lg-3 mt-4 text-center text-md-start"
                  data-aos="fade-up" id="openPosition" >
                  <div className="benifitBox w-100 bg-white p-5">
                    <h2>Leadership Pathways</h2>
                    <p> Aspire to leadership roles with our clear advancement paths and leadership development programs.</p>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}

export default Benefit;
